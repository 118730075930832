import { BuiltinPlugin } from "./typing";

export const CN_PLUGINS: BuiltinPlugin[] = [
  {
    name: "搜索引擎",
    toolName: "web-search",
    lang: "cn",
    description: "搜索引擎的网络搜索功能工具。",
    builtin: true,
    createdAt: 1693744292000,
    enable: true,
    onlyNodeRuntime: false,
  },
  {
    name: "计算器",
    toolName: "calculator",
    lang: "cn",
    description: "计算器是一个用于计算数学表达式的工具。",
    builtin: true,
    createdAt: 1693744292000,
    enable: true,
    onlyNodeRuntime: false,
  },
  {
    name: "网页浏览器",
    toolName: "web-browser",
    lang: "cn",
    description:
      "一个用于与网页进行交互的工具，可以从网页中提取信息或总结其内容。",
    builtin: true,
    createdAt: 1693744292000,
    enable: true,
    onlyNodeRuntime: false,
  },
  {
    name: "维基百科",
    toolName: "WikipediaQueryRun",
    lang: "cn",
    description: "用于与Wikipedia API交互和从Wikipedia API获取数据的工具。",
    builtin: true,
    createdAt: 1694235989000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "DALL·E",
    toolName: "dalle_image_generator",
    lang: "cn",
    description:
      "DALL·E 可以根据自然语言的描述创建逼真的图像和艺术。使用本插件需要配置 Cloudflare R2 对象存储服务。",
    builtin: true,
    createdAt: 1694703673000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "Stable Diffusion",
    toolName: "stable_diffusion_image_generator",
    lang: "cn",
    description:
      "Stable Diffusion 图像生成模型。使用本插件需要配置 Cloudflare R2 对象存储服务以及 stable-diffusion-webui 接口。",
    builtin: true,
    createdAt: 1688899480510,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "Arxiv",
    toolName: "arxiv",
    lang: "cn",
    description: "使用 Arxiv 接口搜索并获取文章信息。",
    builtin: true,
    createdAt: 1699265115000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "PDF浏览器",
    toolName: "pdf-browser",
    lang: "cn",
    description: "可以从pdf文件的url中提取信息或总结其内容。",
    builtin: true,
    createdAt: 1700907315000,
    enable: false,
    onlyNodeRuntime: true,
  },
  {
    name: "WolframAlphaTool",
    toolName: "wolfram_alpha_llm",
    lang: "cn",
    description:
      "在需要回答有关数学、科学、技术、文化、社会和日常生活的问题时非常有用。",
    builtin: true,
    createdAt: 1703846656000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "bilibili视频信息获取",
    toolName: "bilibili_video_info",
    lang: "cn",
    description: "通过bilibili视频ID获取视频信息，如标题、简介等。",
    builtin: true,
    createdAt: 1712394126000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "bilibili视频搜索",
    toolName: "bilibili_video_search",
    lang: "cn",
    description: "通过关键词搜索bilibili视频，并获取视频信息。",
    builtin: true,
    createdAt: 1712394126000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "bilibili听歌识曲",
    toolName: "bilibili_music_recognition",
    lang: "cn",
    description:
      "通过bilibili视频ID进行听歌识曲识别。(建议同时启用`bilibili视频信息获取`插件保证功能完整)",
    builtin: true,
    createdAt: 1712394126000,
    enable: false,
    onlyNodeRuntime: false,
  },
  {
    name: "bilibili视频总结",
    toolName: "bilibili_video_conclusion",
    lang: "cn",
    description:
      "通过bilibili视频ID进行视频总结。(建议同时启用`bilibili视频信息获取`插件保证功能完整)",
    builtin: true,
    createdAt: 1712394126000,
    enable: false,
    onlyNodeRuntime: false,
  },
];
